// Dashboard
#dashboard-buttons {
  float: right;
  margin-top: -10px;
}

.rp-appbar-left {
  padding-left: 52px;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 1280px) {
  .rp-appbar-left {
    padding-left: 22px;
  }
}


// Login
#logo-login {
  display: block;
  margin: 0 auto 1rem;
  width: 150px;
}

// Page Layouts
.rp-paper-main {
  padding: 20px;
}

.headerWithSwitch {
  line-height: 38px;
}

fieldset {
  border: 0;
  padding: 0;
}

.MuiCard-root {
  overflow: visible;
}

/*
** Sidebar
 */

.nav-section-sep {
  display: block;
  height: 0;
  width: 120px;
  margin: 5px 0 5px 68.8px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.1);
}

/*
** Insurance
 */

.insurance-guide,
.document-guide {
  position: -webkit-sticky;
  position: sticky;
  top: 112px;
  z-index: 1200;
  padding: 10px 7px;
  font-size: 11px;
  line-height: 16px;
  text-align: right;

  .ins-title {
    float: left;
    font-weight: bold;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  .ins-cir {
    width: 16px;
    height: 16px;
    margin: 0 5px 0 15px;
  }
}

.document-guide {
  top: 175px;
}

.ins-cir {
  width: 22px;
  height: 22px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 9px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

.ins-noForm {
  background: #aaa;
}

.ins-expired {
  background: #dd0000;
}

.ins-expiring {
  background: #e7d23c;
}

.ins-updated {
  background: rgb(76, 175, 80);
}


/*
** Data Grid
 */

.rp-data-grid {
  border: 0;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer,
.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.MuiDataGrid-colCellWrapper {
  min-width: 100% !important;
}

.rp-data-grid .MuiDataGrid-row {
  cursor: pointer;
}

.rp-datagrid-head {
  margin-bottom: 16px;
}

.rp-datagrid-toolbar {
  padding: 10px 0 0;
  display: flex;
  justify-content: flex-end;
}


.MuiAccordionSummary-root {
  padding: 0px 16px;
}

.accordion-title {
  font-size: 14px;
  line-height: 38px;
  font-weight: 500;
}

.MuiAccordionDetails-root {
  padding: 16px;
}

.MuiDataGrid-toolbarContainer {
  margin-top: 10px;
}

/*
** Loads
 */

.danger {
  color: #990000;
}

/**
 * Shipments
 */

#refresh-button {
  display: inline-block;
  float: right;
  margin: -3px 5px 0 0;
}

.shipment-legend {
  position: -webkit-sticky;
  position: sticky;
  top: 112px;
  z-index: 1200;
  padding: 10px 7px;
  font-size: 11px;
  line-height: 16px;
  text-align: right;

  .ship-title {
    float: left;
    font-weight: bold;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  .ship-cir {
    width: 16px;
    height: 16px;
    margin: 0 5px 0 15px;
  }
}

.ship-cir {
  width: 22px;
  height: 22px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 9px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

.ship-na {
  background: #aaa;
}

.ship-complete {
  background: rgb(76, 175, 80);
}

.ship-warning {
  background: #e7d23c;
}

.ship-late {
  background: #dd0000;
}

.rp-cell-update {
  //color: #9e2a21;
  font-weight: 600;
}

.shipment-location {
  margin: 0 0 5px;
  padding: 0 0 0 17px;
  list-style-type: '»';

  li {
    padding-left: 10px;
  }
}

.geo-backdrop {
  z-index: 9999;
  color: #fff;
  justify-content: center;
  text-align: center;
}


#driver-tracking {
  display: flex;
  height: 36px;
  float: right;
  margin-top: -5px;

  #driver-tracking-label {
    line-height: 34px;
    font-size: 11px;
    padding: 0 20px 0 20px;
    margin-right: 5px;
    //border-right: 1px solid #dfe6ea;
    //border-right: 1px solid rgba(255,255,255,0.12);
  }

  #driver-tracking-icon {
    height: 34px;
    padding: 8px 10px 0 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    color: #fff;
  }

  #driver-tracking-buttons {
    padding: 3px 10px 0 0;

    button {
      margin-left: 5px;
    }
  }
}

/*
** Maps
 */

#shipment-map-wrapper {
  position: relative;
  height: 400px;
  margin-top: 20px;
}

#shipment-map-loader {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(#fff, 0.4);
}

#shipment-map {
  width: 100%;
  height: 100%;
}

#infowindow-content {
  color: #000;
}

/*
** Contracts
 */

.rp-contract-title {
  padding: 20px 25px;
}

.rp-contract-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

/*
** BOLs
 */

.rp-bol-title {
  padding: 20px 25px;
}

.rp-bol-close {
  position: absolute;
  top: 10px;
  right: 10px;
}


/*
** Invoices
 */

.MuiDropzoneArea-root {
  min-height: 110px;
}

.MuiDropzoneSnackbar-message {
  white-space: pre-wrap;
}

/*
** Account
 */

.tabs-container {
  display: flex;
  flex-grow: 1;
}

.tabs {
  flex-basis: 160px;
  min-width: 160px;
  border-right: 1px solid #ccc;
}

.tab-content {
  flex-grow: 1;
  padding: 14px 20px 0 36px;
}

.MuiTab-wrapper {
  align-items: start;
}

/*
** TRACKING PUBLIC
 */

#track-wrapper {
  min-height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#track-inner {
  width: 100%;
  padding: 40px 20px;
  max-width: 600px;
}

#track-form {
  text-align: center;
}

#track-content {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
}

#track-info {
  width: auto;
  margin: auto;

  #track-info-inner {
    padding: 10px;
    background: #fafafa;
    border: 1px solid #ddd;
    min-width: 360px;
  }

  h4 {
    margin-bottom: 20px;
    text-align: center;
  }
}

#track-timeline {
  padding-right: 22px;

  ul {
    padding: 0;
  }

  .shipment-icon {
    padding: 6px;
    font-size: 18px;
    color: rgba(#990000, 0.8);
  }
}

/*
** DRIVERS PUBLIC
 */

#driver-app-wrapper {
  padding: 2rem 0;

  a {
    color: #900;
  }

  #logo {
    display: block;
    margin: 0 auto 1rem;
    width: 150px;
  }

  .agreement {
    li {
      margin-bottom: 10px;
    }
  }
}

